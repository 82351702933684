import React from 'react';
import Grid from '@components/core/layout/Grid';
import Button from '@components/core/Button';
import Icon from '@components/core/Icon';
import { getInternalPath } from '@helpers/internalLink';

export default function Calculator2(props) {
  const ctaLink = props.ctaButton?.link.internalLink ? getInternalPath(props.ctaButton?.link?.internalLink) : props.ctaButton?.link?.externalUrl;

  return (
    <Grid>
      <div className='xl:col-span-3 xl:col-start-1 col-span-12'>
        <div className='xl:text-heading-lg text-heading-md mb-4'>{ props.title }</div>
        <div className='xl:text-body-lg text-body-md'>{ props.intro }</div>
        { props.ctaButton &&
          <Button type='text' href={ ctaLink } externalUrl={ props.ctaButton?.link.externalUrl } className='mt-8'>
            { props.ctaButton.label }
            <Icon name={ 'ArrowForward' } className='ml-3' />
          </Button>
        }
      </div>
      <div id='idIframediv' className='xl:col-start-5 xl:col-end-13 col-span-12'>
        <iframe style={ { width: '100%', height: '650px' } } src={ `${ process.env.NEXT_PUBLIC_BASE_URL }/interne-rekentool` } title='rekentool' />
      </div>
    </Grid>
  );
}
